<template>
  <div>
    <checkData :key="$route.name">
      <calendarPreview />
    </checkData>
  </div>
</template>
<script>
export default {
  components: {
    calendarPreview() {
      return import("@/components/business/preview");
    },
    checkData() {
      return import("@/components/business/check");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
